import React from "react";
import { Link as LinkRoll } from "react-scroll";
import "./TherapySA.css";
import { Link } from "react-router-dom";
import Alcohol from "../../assets/alcohol.webp";
import Prescriptions from "../../assets/prescriptions.webp";
import Heroin from "../../assets/heroin.webp";
import Xanax from "../../assets/xanax.webp";

const TherapySA = () => {
  return (
    <div className="substanceAbuse">
      <div className="substanceAbuseContent">
        <div className="substanceAbuseHeader">
          <h1>Types of Substance Addiction Disorder We Treat</h1>
          <p>
            Here at Auburn Rehab, we offer a full array
            of affordable drug rehab programs that can take addicts from
            detox to inpatient or outpatient care and back home again.
          </p>
        </div>
        <div className="substanceAbuseFlex">
          <div className="substanceAbuseFlexItem">
            <div className="flexItemIcon">
              <img src={Alcohol} alt="Alcohol" loading="lazy" />
            </div>
            <h1>ALCOHOL</h1>
            <p>
              Inability to manage one’s drinking habits. It is the most
              acute form of alcohol abuse.
            </p>
          </div>
          <div className="substanceAbuseFlexItem">
            <div className="flexItemIcon">
              <img src={Prescriptions} alt="Prescriptions" loading="lazy" />
            </div>
            <h1>PRESCRIPTIONS</h1>
            <p>
              Abuse of prescription medications can be recreational, due to
              addiction, or both, and the consequences can be severe or
              fatal.
            </p>
          </div>
          <div className="substanceAbuseFlexItem">
            <div className="flexItemIcon">
              <img src={Heroin} alt="Heroin" loading="lazy" />
            </div>
            <h1>HEROIN</h1>
            <p>
              Heroin is an opioid stimulant created from morphine and the
              seed of the poppy plant. It usually appears in either powder
              form or as a sticky tar substance.
            </p>
          </div>
          <div className="substanceAbuseFlexItem">
            <div className="flexItemIcon">
              <img src={Xanax} alt="Xanax" loading="lazy" />
            </div>
            <h1>XANAX</h1>
            <p>
              When taken in high doses, Xanax can lead to serious side
              effects, ranging from coma, to respiratory arrest, and even
              death.
            </p>
          </div>
        </div>
        <div className='sa-btn'>
          <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
            <Link to="/substance-abuse">
              <button>Explore Addiction Treatment Programs</button>
            </Link>
          </LinkRoll>
        </div>
      </div>
    </div>
  );
};

export default TherapySA;
